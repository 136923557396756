import { format, parseISO } from 'date-fns';
import { Link } from 'gatsby';
import { sortBy } from 'lodash';
import React, { ComponentType, FC } from 'react';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import { GranularityEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import formatMoney from '@/utils/formatter/formatMoney';
import { makeFluidThumbnail } from '@/utils/makeFluidThumbnail';
import { PlayerEntry } from './helpers';

type BaseValueProps = {
  value: Nullable<any>;
};

type Formatter<ValueType> = (value: ValueType) => string | number;
type NumberFormatter = Formatter<Nullable<number>>;
type ToStringFormatter<ValueType> = (value: ValueType) => string;
type NumberToStringFormatter = ToStringFormatter<Nullable<number>>;

export type ValueConfig<T extends BaseValueProps = BaseValueProps> = {
  name: string;
  fieldName?: string;
  label: string;
  Component: ComponentType<T>;
  toFormattedValue: Formatter<any>;
  staticProps: Omit<T, 'value'>;
};

const isValidNumber = (value: unknown): value is number => {
  return typeof value === 'number' && value != null && !isNaN(value);
};

const percentageValueToStringValue: NumberToStringFormatter = (value) => {
  if (isValidNumber(value)) {
    const pct = (value * 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${pct}%`;
  }
  return 'N/A';
};

const PercentageValue: FC<{ value: Nullable<number> }> = ({ value }) => (
  <>{percentageValueToStringValue(value)}</>
);

const monetaryValueToStringValue: NumberToStringFormatter = (value) => {
  if (isValidNumber(value)) {
    return formatMoney(value);
  }
  return 'N/A';
};

const formatMonetaryValue: NumberFormatter = (value) =>
  isValidNumber(value) ? value : 'N/A';

const MonetaryValue: FC<{ value: Nullable<number> }> = ({ value }) => (
  <>{monetaryValueToStringValue(value)}</>
);

const numberValueToStringValue: NumberToStringFormatter = (value) => {
  if (isValidNumber(value)) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
  return 'N/A';
};

const formatNumberValue: NumberFormatter = (value) =>
  isValidNumber(value) ? value : 'N/A';

const NumberValue: FC<{ value: Nullable<number> }> = ({ value }) => (
  <>{numberValueToStringValue(value)}</>
);

const stringValueToStringValue: ToStringFormatter<Nullable<string>> = (value) =>
  value ?? '';

const StringValue: FC<{ value: Nullable<string> }> = ({ value }) => (
  <>{stringValueToStringValue(value)}</>
);

type GameValueProp = { json?: { name?: string } };

const gameValueToStringValue: ToStringFormatter<Nullable<GameValueProp>> = (
  value,
) => {
  return value?.json?.name || '';
};

const GameValue: FC<{ value: Nullable<GameValueProp> }> = ({ value }) => {
  return <>{gameValueToStringValue(value)}</>;
};

type ThumbnailValueProp = {
  json?: { name: string; thumbnail: string; thumbnailUpdatedAt: number };
};

const thumbnailValueToStringValue: ToStringFormatter<
  Nullable<ThumbnailValueProp>
> = (value) => {
  return value?.json?.thumbnail || '';
};

const ThumbnailValue: FC<{
  value: Nullable<ThumbnailValueProp>;
}> = ({ value }) => {
  if (!value?.json) {
    return null;
  }

  const game = value.json;
  const fluid = makeFluidThumbnail(game.thumbnail, game.thumbnailUpdatedAt);

  return (
    <picture>
      <source sizes={fluid.sizes} srcSet={fluid.srcSet} />
      <img
        src={fluid.src}
        alt={game?.name}
        loading="lazy"
        className="w-12 h-12 inline-block rounded-sm mr-1"
      />
    </picture>
  );
};

const CountryValue: FC<{ value: Nullable<string> }> = ({ value }) => (
  <StringValue value={value} />
);

const playerValueToStringValue: ToStringFormatter<Nullable<PlayerEntry>> = (
  value,
) => {
  const name = [value?.firstName, value?.lastName].filter(Boolean).join(' ');
  return name || '(no name)';
};

const PlayerValue: FC<{
  value: PlayerEntry;
}> = ({ value }) => {
  const getPlayerOverviewPageLink = useGetPlayerOverviewPageLink();

  if (value) {
    const link = getPlayerOverviewPageLink(value.id);
    const playerName = playerValueToStringValue(value);
    return link ? <Link to={link}>{playerName}</Link> : <>{playerName}</>;
  }

  return null;
};

const createPlayerMetaValue = (
  toFormattedValue: (value: PlayerEntry) => string,
) => {
  const Component: FC<{
    value: PlayerEntry;
  }> = ({ value }) => {
    return <>{toFormattedValue(value)}</>;
  };

  return Component;
};

const granularityFormat: Record<GranularityEnum, string | undefined> = {
  [GranularityEnum.All]: undefined,
  [GranularityEnum.Year]: 'yyyy',
  [GranularityEnum.Quarter]: 'yyyy qqq',
  [GranularityEnum.Week]: 'yyyy I',
  [GranularityEnum.Month]: 'yyyy-MM',
  [GranularityEnum.Day]: 'yyyy-MM-dd',
  [GranularityEnum.Hour]: 'yyyy-MM-dd HH:00',
  [GranularityEnum.Minute]: 'yyyy-MM-dd HH:mm',
  [GranularityEnum.Second]: 'yyyy-MM-dd HH:mm:ss',
};

const parseISODate = (dateString: Nullable<string>) => {
  if (!dateString) {
    return undefined;
  }

  const d = parseISO(dateString);
  return isNaN(d.getTime()) ? undefined : d;
};

const timePeriodValueToStringValue = (
  granularity: GranularityEnum,
): ToStringFormatter<Nullable<string>> => (value) => {
  const d = parseISODate(value);
  const dateFormat = granularityFormat[granularity];

  if (d && dateFormat) {
    return format(d, dateFormat, {
      weekStartsOn: 1,
    });
  }

  if (!dateFormat) {
    return 'N/A';
  }

  return '';
};

const TimePeriodValue: FC<{ value: string; granularity: GranularityEnum }> = ({
  value,
  granularity,
}) => <>{timePeriodValueToStringValue(granularity)(value)}</>;

type BaseOptions = {
  name: string;
  label: string;
  fieldName?: string;
};

function valueFactory<
  Options extends BaseOptions = BaseOptions,
  Props extends BaseValueProps = BaseValueProps
>(
  Component: ComponentType<Props>,
  toFormattedValue: ValueConfig['toFormattedValue'],
  staticProps: Omit<Props, 'value'>,
) {
  return function (options: Options): ValueConfig<Props> {
    return {
      Component,
      toFormattedValue,
      staticProps,
      ...options,
    };
  };
}

const percentageValue = valueFactory(
  PercentageValue,
  percentageValueToStringValue,
  {},
);
const monetaryValue = valueFactory(MonetaryValue, formatMonetaryValue, {});
const numberValue = valueFactory(NumberValue, formatNumberValue, {});
const stringValue = valueFactory(StringValue, stringValueToStringValue, {});
const gameValue = valueFactory(GameValue, gameValueToStringValue, {});
const thumbnailValue = valueFactory(
  ThumbnailValue,
  thumbnailValueToStringValue,
  {},
);
const countryValue = valueFactory(CountryValue, stringValueToStringValue, {});
const playerValue = valueFactory(PlayerValue, playerValueToStringValue, {});

const createTimePeriodValue = (granularity: GranularityEnum) =>
  valueFactory(TimePeriodValue, timePeriodValueToStringValue(granularity), {
    granularity,
  });

export const timePeriodDimensionsMap: Record<
  string,
  ValueConfig<{ granularity: GranularityEnum; value: string }>
> = {
  all: createTimePeriodValue(GranularityEnum.All)({
    name: 'all',
    label: 'All',
    fieldName: 'timePeriod',
  }),
  day: createTimePeriodValue(GranularityEnum.Day)({
    name: 'day',
    label: 'Day',
    fieldName: 'timePeriod',
  }),
  hour: createTimePeriodValue(GranularityEnum.Hour)({
    name: 'hour',
    label: 'Hour',
    fieldName: 'timePeriod',
  }),
  minute: createTimePeriodValue(GranularityEnum.Minute)({
    name: 'minute',
    label: 'Minute',
    fieldName: 'timePeriod',
  }),
  month: createTimePeriodValue(GranularityEnum.Month)({
    name: 'month',
    label: 'Month',
    fieldName: 'timePeriod',
  }),
  quarter: createTimePeriodValue(GranularityEnum.Quarter)({
    name: 'quarter',
    label: 'Quarter',
    fieldName: 'timePeriod',
  }),
  second: createTimePeriodValue(GranularityEnum.Second)({
    name: 'second',
    label: 'Second',
    fieldName: 'timePeriod',
  }),
  week: createTimePeriodValue(GranularityEnum.Week)({
    name: 'week',
    label: 'Week',
    fieldName: 'timePeriod',
  }),
  year: createTimePeriodValue(GranularityEnum.Year)({
    name: 'year',
    label: 'Year',
    fieldName: 'timePeriod',
  }),
};

export const checkHasTimeRangeDimension = (selectedDimensions: string[]) => {
  return selectedDimensions.some((val) => val in timePeriodDimensionsMap);
};

const nonTimePeriodDimensionsMap: Record<string, ValueConfig> = {
  affiliateId: stringValue({ name: 'affiliateId', label: 'Affiliate ID' }),
  birthYear: stringValue({ name: 'birthYear', label: 'Birth Year' }),
  brand: stringValue({ name: 'brand', label: 'Brand' }),
  clientCountryCode: countryValue({
    name: 'clientCountryCode',
    label: 'Client Country Code',
  }),
  currency: stringValue({ name: 'currency', label: 'Currency' }),
  device: stringValue({ name: 'device', label: 'Device' }),
  deviceFingerprint: stringValue({
    name: 'deviceFingerprint',
    label: 'Device Fingerprint',
  }),
  gameDescriptor: gameValue({ name: 'gameDescriptor', label: 'Game' }),
  gameId: stringValue({ name: 'gameId', label: 'Game ID' }),
  gameProvider: stringValue({ name: 'gameProvider', label: 'Game Provider' }),
  gameSessionId: stringValue({
    name: 'gameSessionId',
    label: 'Game Session ID',
  }),
  gender: stringValue({ name: 'gender', label: 'Gender' }),
  isBot: stringValue({ name: 'isBot', label: 'isBot' }),
  isIncognito: stringValue({ name: 'isIncognito', label: 'Is Incognito' }),
  os: stringValue({ name: 'os', label: 'OS' }),
  paymentProvider: stringValue({
    name: 'paymentProvider',
    label: 'Payment Provider',
  }),
  player: playerValue({ name: 'player', label: 'Player' }),
  playerId: stringValue({ name: 'playerId', label: 'Player ID' }),
  playerSessionId: stringValue({
    name: 'playerSessionId',
    label: 'Player Session ID',
  }),
  residenceCountryCode: countryValue({
    name: 'residenceCountryCode',
    label: 'Residence Country Code',
  }),
  thumbnail: thumbnailValue({
    name: 'thumbnail',
    label: 'Thumbnail',
    fieldName: 'gameDescriptor',
  }),
};

const playerMetaValueFactory = ({
  toFormattedValue,
  ...options
}: BaseOptions & {
  toFormattedValue: (value: PlayerEntry) => string;
}) => {
  return valueFactory(
    createPlayerMetaValue(toFormattedValue),
    toFormattedValue,
    {},
  )(options);
};

const boolToString = (bool: Nullable<boolean>) => (bool ? 'Yes' : 'No');

const metaDimensionsMap: Record<string, ValueConfig> = {
  metaEmail: playerMetaValueFactory({
    label: 'Email (meta)',
    name: 'metaEmail',
    fieldName: 'player',
    toFormattedValue: (v) => v?.email ?? 'N/A',
  }),
  metaPhoneNumber: playerMetaValueFactory({
    label: 'Phone Number (meta)',
    name: 'metaPhoneNumber',
    fieldName: 'player',
    toFormattedValue: (v) => v?.phoneNumber ?? 'N/A',
  }),
  metaIsEmailMarketingAccepted: playerMetaValueFactory({
    label: 'E-mail Marketing Accepted (meta)',
    name: 'metaIsEmailMarketingAccepted',
    fieldName: 'player',
    toFormattedValue: (v) => boolToString(v?.isEmailMarketingAccepted),
  }),
  metaIsSmsMarketingAccepted: playerMetaValueFactory({
    label: 'SMS Marketing Accepted (meta)',
    name: 'metaIsSmsMarketingAccepted',
    fieldName: 'player',
    toFormattedValue: (v) => boolToString(v?.isSmsMarketingAccepted),
  }),
  metaAccountStatus: playerMetaValueFactory({
    label: 'Account Status (meta)',
    name: 'metaAccountStatus',
    fieldName: 'player',
    toFormattedValue: (v) => v?.accountStatus?.status ?? 'N/A',
  }),
};

const sortByLabel = (collection: ValueConfig<any>[]) => {
  return sortBy(collection, (a) => a.label.toLowerCase().trim());
};

export const dimensionsSchema: ValueConfig[] = sortByLabel([
  ...Object.values(timePeriodDimensionsMap),
  ...Object.values(nonTimePeriodDimensionsMap),
  ...Object.values(metaDimensionsMap),
]);

const dimensionFromName = (name: string): ValueConfig<any> | undefined => {
  return (
    timePeriodDimensionsMap[name] ||
    nonTimePeriodDimensionsMap[name] ||
    metaDimensionsMap[name]
  );
};

const valuesMap: Record<string, ValueConfig> = {
  amountAdjustments: monetaryValue({
    name: 'amountAdjustments',
    label: 'Adjustment Amount',
  }),
  amountBonusAdjustments: monetaryValue({
    name: 'amountBonusAdjustments',
    label: 'Bonus Adjustment Amount',
  }),
  amountCashbacks: monetaryValue({
    name: 'amountCashbacks',
    label: 'Cashback Amount',
  }),
  amountDeposits: monetaryValue({
    name: 'amountDeposits',
    label: 'Deposit Amount',
  }),
  amountReversals: monetaryValue({
    name: 'amountReversals',
    label: 'Deposit Reversals Amount',
  }),
  amountFtd: monetaryValue({ name: 'amountFtd', label: 'FTD Amount' }),
  amountFailedDeposits: monetaryValue({
    name: 'amountFailedDeposits',
    label: 'Failed Deposit Amount',
  }),
  amountFailedWithdrawals: monetaryValue({
    name: 'amountFailedWithdrawals',
    label: 'Failed Withdrawal Amount',
  }),
  ggr: monetaryValue({ name: 'ggr', label: 'GGR' }),
  allWins: monetaryValue({ name: 'allWins', label: 'Game Win' }),
  hold_: monetaryValue({ name: 'hold_', label: 'Hold' }),
  jpc: monetaryValue({ name: 'jpc', label: 'JPC' }),
  ngr: monetaryValue({ name: 'ngr', label: 'NGR' }),
  turnover: monetaryValue({ name: 'turnover', label: 'Turnover' }),
  amountWithdrawableWinnings: monetaryValue({
    name: 'amountWithdrawableWinnings',
    label: 'Withdrawable Winnings Amount',
  }),
  amountWithdrawals: monetaryValue({
    name: 'amountWithdrawals',
    label: 'Withdrawal Amount',
  }),
  ftd: numberValue({ name: 'ftd', label: 'FTD' }),
  nrc: numberValue({ name: 'nrc', label: 'NRC' }),
  nrcByNsc: numberValue({
    name: 'nrcByNsc',
    label: 'New Registered customers in terms of new signed up customers',
  }),
  nsc: numberValue({ name: 'nsc', label: 'New Signed Up Customers' }),
  numUniqueActivePlayers: numberValue({
    name: 'numUniqueActivePlayers',
    label: 'Num. Active Players',
  }),
  numAdjustments: numberValue({
    name: 'numAdjustments',
    label: 'Num. Adjustments',
  }),
  wagers: numberValue({ name: 'wagers', label: 'Num. Bets' }),
  numCashbacks: numberValue({ name: 'numCashbacks', label: 'Num. Cashbacks' }),
  numReversals: numberValue({
    name: 'numReversals',
    label: 'Num. Deposit Reversals',
  }),
  numUniqueDepositors: numberValue({
    name: 'numUniqueDepositors',
    label: 'Num. Depositors',
  }),
  numDeposits: numberValue({ name: 'numDeposits', label: 'Num. Deposits' }),
  numFailedDeposits: numberValue({
    name: 'numFailedDeposits',
    label: 'Num. Failed Deposits',
  }),
  numFailedWithdrawals: numberValue({
    name: 'numFailedWithdrawals',
    label: 'Num. Failed Withdrawals',
  }),
  numUniqueSessions: numberValue({
    name: 'numUniqueSessions',
    label: 'Num. Player Sessions',
  }),
  numUniquePlayers: numberValue({
    name: 'numUniquePlayers',
    label: 'Num. Players',
  }),
  numWithdrawals: numberValue({
    name: 'numWithdrawals',
    label: 'Num. Withdrawals',
  }),
  numBlockAccountRequests: numberValue({
    name: 'numBlockAccountRequests',
    label: 'Number of Block Accounts Requests',
  }),
  numCloseAccountRequests: numberValue({
    name: 'numCloseAccountRequests',
    label: 'Number of Close Accounts Requests',
  }),
  numReopenAccountRequests: numberValue({
    name: 'numReopenAccountRequests',
    label: 'Number of Reopen Accounts Requests',
  }),
  numCancelSelfExclusionRequests: numberValue({
    name: 'numCancelSelfExclusionRequests',
    label: 'Number of Self Exclusion Cancellation Requests',
  }),
  numSelfExclusionRequests: numberValue({
    name: 'numSelfExclusionRequests',
    label: 'Number of Self Exclusion Requests',
  }),
  numTotalCloseAccountRequests: numberValue({
    name: 'numTotalCloseAccountRequests',
    label: 'Number of Total Close Accounts Requests',
  }),
  ftdOfNrc: percentageValue({ name: 'ftdOfNrc', label: 'FTD of NRC' }),
  margin: percentageValue({ name: 'margin', label: 'Margin' }),
};

export const valuesSchema: ValueConfig[] = sortByLabel(
  Object.values(valuesMap),
);

const valueFromName = (name: string): ValueConfig | undefined =>
  valuesMap[name];

export const getValueConfig = (name: string) => {
  return dimensionFromName(name) || valueFromName(name);
};
