import React from 'react';

import { DevtoolsWrapper } from '@/components';
import { StatsRow } from '../helpers';
import TableVisualization from './TableVisualization';

const defaultStatsValue = {
  __typename: '',
  timePeriod: null,
  player: null,
  gameDescriptor: null,
  affiliateId: null,
  allWins: null,
  amountAdjustments: null,
  amountBonusAdjustments: null,
  amountCashbacks: null,
  amountDeposits: null,
  amountFailedDeposits: null,
  amountFailedWithdrawals: null,
  amountFtd: null,
  amountReversals: null,
  amountWithdrawableWinnings: null,
  amountWithdrawals: null,
  birthYear: null,
  brand: null,
  clientCountryCode: null,
  currency: null,
  device: null,
  deviceFingerprint: null,
  ftd: null,
  ftdOfNrc: null,
  gameId: null,
  gameProvider: null,
  gameSessionId: null,
  gender: null,
  ggr: null,
  hold_: null,
  isBot: null,
  isIncognito: null,
  jpc: null,
  margin: null,
  ngr: null,
  nrc: null,
  nrcByNsc: null,
  nsc: null,
  numAdjustments: null,
  numBlockAccountRequests: null,
  numCancelSelfExclusionRequests: null,
  numCashbacks: null,
  numCloseAccountRequests: null,
  numDeposits: null,
  numFailedDeposits: null,
  numFailedWithdrawals: null,
  numReopenAccountRequests: null,
  numReversals: null,
  numSelfExclusionRequests: null,
  numTotalCloseAccountRequests: null,
  numUniqueActivePlayers: null,
  numUniqueDepositors: null,
  numUniquePlayers: null,
  numUniqueSessions: null,
  numWithdrawals: null,
  os: null,
  paymentProvider: null,
  playerId: null,
  playerSessionId: null,
  residenceCountryCode: null,
  turnover: null,
  wagers: null,
};

const createStatsValue = (
  entry: Partial<StatsRow> & Pick<StatsRow, '__typename'>,
): StatsRow => ({
  ...defaultStatsValue,
  ...entry,
});

const stats: StatsRow[] = [
  createStatsValue({
    timePeriod: '2021-04-05T00:00:00.000Z',
    currency: 'CAD',
    turnover: 1104.3,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-05T00:00:00.000Z',
    currency: 'EUR',
    turnover: 113.01,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-12T00:00:00.000Z',
    currency: 'CAD',
    turnover: 290,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-12T00:00:00.000Z',
    currency: 'EUR',
    turnover: 28.2,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-12T00:00:00.000Z',
    currency: 'NOK',
    turnover: 20,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-12T00:00:00.000Z',
    currency: 'NZD',
    turnover: 4,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-12T00:00:00.000Z',
    currency: 'ZAR',
    turnover: 80,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-19T00:00:00.000Z',
    currency: 'CAD',
    turnover: 162,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-19T00:00:00.000Z',
    currency: 'EUR',
    turnover: 27.35,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-19T00:00:00.000Z',
    currency: 'NZD',
    turnover: 0,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-19T00:00:00.000Z',
    currency: 'ZAR',
    turnover: 105,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-26T00:00:00.000Z',
    currency: 'CAD',
    turnover: 1297,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-26T00:00:00.000Z',
    currency: 'EUR',
    turnover: 12529.650000000001,
    __typename: 'StatsEntryType',
  }),
  createStatsValue({
    timePeriod: '2021-04-26T00:00:00.000Z',
    currency: 'NOK',
    turnover: 1022.5,
    __typename: 'StatsEntryType',
  }),
];

const example = () => {
  return (
    <DevtoolsWrapper>
      <TableVisualization
        columns={['currency']}
        rows={['day']}
        values={['turnover']}
        stats={stats}
      />
    </DevtoolsWrapper>
  );
};

export default example;
